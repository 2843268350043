import React from 'react'

export default function LogoPng({ fullWidth = false, color = 'white' }) {
  return (
    <img
      src={color === 'black' ? '/yodo_logo.svg' : '/yodo_logo.png'}
      alt="Logo"
      width={fullWidth ? '100%' : 140}
    />
  )
}
