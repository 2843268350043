import { makeStyles } from '@material-ui/core/styles'

const commonStyles = makeStyles((theme) => ({
  smallContainer: {
    maxWidth: '500px',
    margin: 'auto',
    padding: 25
  },
  alignCenter: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  h1: {
    color: theme.palette.primary.main
  },
  bigTitle: {
    fontSize: '53px'
  },
  bigLabel: {
    fontSize: '35px'
  },
  mainContentContainer: {
    marginTop: theme.spacing(20)
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  bigContentContainer: {
    marginTop: theme.spacing(20),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90%'
  },
  [`@media print`]: {
    bigContentContainer: {
      width: '100%',
      overflow: 'hidden'
    },
    mainContentContainer: {
      marginTop: theme.spacing(5)
    }
  }
}))

export default commonStyles
